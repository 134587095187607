import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import InnerBanner from './InnerBanner';
import locationmap from '../assets/images/location-map.png';
import $ from 'jquery';
import Sound from './Sound';
import { useParams } from 'react-router-dom';
import { getAttractionSingle } from "../../../utils/api";
import parse from 'html-react-parser';
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";
function Attractions() {
 
  
  const [attractions, setAttractions] = useState([]);
  //const [isOpen, setIsOpen] = useState(false);
 
  const params = useParams()
  useEffect(() => {  
    
    getAttractionSingle(params.attractionName,'en').then(attractionValues=>{
      if(attractionValues.length > 0){
      setAttractions(attractionValues[0]);
      }     
        
    });     
     
   },[]);  
  
    $(window).on('scroll', function() {
      if ($(window).scrollTop() >= $('.cave_sec_box').offset().top - window.innerHeight) {
            $('.cave_sec_boxleft').addClass('first');
            $('.cave_sec_boxright').addClass('second');
           // $('.cave_sec1').addClass('fadeInLeftlocal first');
        }
    });

 function revealUp() {
  var reveals = document.querySelectorAll(".reveal-up");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 1;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", revealUp);

    return (
      <div className="Apphome Apphomeenglishs">
        <Header />
        <InnerBanner title={attractions.other_name} bannerimage={attractions.other_banner_image} />
        <div className="wrapper">
          <div className="cave_sec">
              <div className="cave_sec_box">
                <div className="cave_sec_boxleft reveal-up">
                 {attractions.other_contents !== undefined && parse(attractions.other_contents)}
                </div>
                <div className="cave_sec_boxright reveal-up">
                  <h2>Location map</h2>
                  {/* <a href='#' onClick={() => setIsOpen(true)}><img src={attractions.other_pdf} alt=""/></a> */}
                  <a href='javascript:void(0)'><img src={attractions.other_pdf} alt=""/></a>
                </div>
              </div>
              <div className="cave_sec1 reveal-up">
            <h3><span>Visiting Hours:</span><i>{attractions.time}</i></h3>
            <h3><span>Entrance Fees:</span><i>{attractions.other_price !== undefined && parse(attractions.other_price)}</i></h3>
            </div>
            {/* {isOpen && <Lightbox
              
              mainSrc={attractions.other_pdf}
              
              onCloseRequest={() => setIsOpen(false)}
             
            />} */}
          </div>
        </div>
        
      <Sound /> 
      <Footer />
      </div>

       

    );
}
export default Attractions;