import React from "react";
//import { NavLink } from "react-router-dom";
//import Header from '../Layout/Header';  
import banicon from '../assets/images/banicon.png';
import longarrowleft from '../assets/images/longarrowleft.png';
  

function Figstick() {
    return (
<section className="figstick">
	<a href="/en/orchard/Olive-and-Fig">
	<img src={longarrowleft} className="longarrowleft" alt="figstick"/>
	<img src={banicon} className="baniconmob" alt="figstick"/>
	<h2>Figs</h2>
</a>
</section>     
 

    );
}
export default Figstick;