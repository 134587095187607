import React, { useState, useEffect } from 'react'; 
//import {Link} from "react-router-dom";
import logos from '../assets/images/mainlogo.png';
import dubaiImg from '../assets/images/dubaiimg.png';
import hamberimg from '../assets/images/hamberimg.png';
import dashlogo from '../assets/images/dashboard-logo.png';
import downarrow from '../assets/images/down-arrow.png';
//import $ from 'jquery'; 
import iconsclose from '../assets/images/iconsclose.png';
import $ from 'jquery'; 
import homeicon from '../assets/images/home-icon.png';
import banicon from '../assets/images/banicon.png';
import oliveicon from '../assets/images/oliveicon.png';
import worldicon from '../assets/images/world-icon.png';
import mobilclose from '../assets/images/mobilclose.png';
import { getSettings } from "../../../utils/api";
import "../Pages/Fontawesome.min.css";
import '../Pages/Custom.css';

import { useParams } from 'react-router-dom';
import { getCategories } from "../../../utils/api";

function Header() {
    const [sellang, setSellang] = useState("");

    const [categories, setCategories] = useState([]);
  const [orchardName, setorchardName] = useState('');
  const params = useParams()
  useEffect(() => { 
    
    getCategories(params.orchardName,'ar').then(catvalues=>{
      if(catvalues.result_set.length > 0){
        setCategories(catvalues.result_set);     
        setorchardName(catvalues.orchard_name);
      }  
    });     
     
   },[]);  

    function Hamber() {
        var element = document.getElementById("myDIV");
        var heads = document.getElementById("myhead");
       element.classList.add("mystyle");
       heads.classList.add("myhead");
       $('body').addClass("menu-running");
       $('body').removeClass("menu-close");
       $('.mobil_stick_nav').addClass("fadeInDownlocal");
    }
    function menuClose() {
        var element = document.getElementById("myDIV");
        var heads = document.getElementById("myhead");
       element.classList.remove("mystyle");
       heads.classList.remove("myhead");
       $('body').addClass("menu-close");
       $('body').removeClass("menu-running");
    }
    function Closed() { 
        var element = document.getElementById("myDIV");
        var heads = document.getElementById("myhead");
       element.classList.remove("mystyle");
       heads.classList.remove("myhead");
       $('body').addClass("menu-close");
       $('body').removeClass("menu-running");
       $('.mobil_stick_nav').removeClass("fadeInDownlocal");
    }

    
    // const  redirectToOrchardEN = ()=>{
    //     let currentURL = window.location.href;
    //     let result = currentURL.replace("ar", "en");
    //     window.location = result;
    //   }
    
    //   const  redirectToOrcharAR = ()=>{
    //     let currentURL = window.location.href;
    //     let result = currentURL.replace("ar", "ar");
    //     window.location = result;
    // } 
    
    // const  redirectToOrchardURD = ()=>{
    //     let currentURL = window.location.href;
    //     let result = currentURL.replace("ar", "urd");
    //     window.location = result;
    // }

const  redirectToOrchardEN = ()=>{
    
    window.location = '/en/orchard/'+localStorage.getItem('slug')
  }

  const  redirectToOrcharAR = ()=>{
    window.location = '/ar/orchard/'+localStorage.getItem('slug')
} 

const  redirectToOrchardURD = ()=>{
    window.location = '/urd/orchard/'+localStorage.getItem('slug')
}


 
    
    const [settings, setSettings] = useState([]);
    
  
    useEffect(() => {  
      
      getSettings('ar').then(settingValues=>{
          if(settingValues.length > 0){
              setSettings(settingValues[0]);     
          }
    
      });     
     },[]); 
     

     const changeLanguage = (event)=>{
        setSellang(event.target.value);
        if(event.target.value==='en'){
            redirectToOrchardEN();
        }
        if(event.target.value==='ar'){
            redirectToOrcharAR();
        }
        if(event.target.value==='urd'){
            redirectToOrchardURD();
        }
        
        }
        
    
  return ( 
    <header id="myhead" className="arabicheader language__change"> 
    	<div className="wrapper fadeInDownlocal">
    		<div className="header-main ">
    			<div className="header-left">
          <a href="javascript:void(0)"><img src={logos} alt="logo" /></a>
    		 	</div>
    			<div className="header-right">
          <a href="javascript:void(0)"><img src={dubaiImg} alt="dubai" /></a>
    			</div>
    		</div>
    		<div className="header-bottom">
    			<div className="header-bottomleft fadeInRightlocal">
                    <img src={mobilclose} alt="Language" className="sitelogoimg-mobile" onClick={Closed}/>
    				<img src={hamberimg} alt="hamber" className="hamberimg" onClick={Hamber}/>
    				<a href={"/ar/orchard/"+localStorage.getItem('slug')}><img src={settings.logo} alt="Language" className="sitelogoimg" /></a>
    			</div>
    			<div className="header-bottomright fadeInLeftlocal">
                    <select style={{ backgroundImage: `url(${downarrow})`}}  value={"ar"} onChange={changeLanguage} >
                    <option onClick={redirectToOrcharAR} value="ar">عربي</option>
                      <option onClick={redirectToOrchardURD} value="urd">اردو</option>
                      <option onClick={redirectToOrchardEN} value="en">English</option>
                   
                    </select>
                     <a href="/ar/trivia">{settings.trivia_title_ar}</a>
    			</div>
    		</div>
    	</div>
        <div className="site-menu-panel"></div>
        <div className="mainmenu" id="myDIV">
        <div className="mainmenumyDIV">
            <ul>
                <li><a href={"/ar/orchard/"+params.orchardName}>الصفحة الرئيسية</a></li>
                {categories!== undefined &&  categories.length > 0 && <ul className="">
                {categories.map(function(item){ 
                    return <li>
                    <a href={"/ar/orchard/"+item.orchard_lang_slug+"/"+item.slug_name}>{item.subplants_name}</a>
                  </li> 
                })}  
                </ul>}
                <li><a href="/ar/trivia">{settings.trivia_title_ar}</a></li>
            </ul>
            </div>
            <p className="timescircle" onClick={menuClose}><img src={iconsclose} alt=""/></p>
        </div>
        <div className="mobil_stick_nav arabicmobil_stick_nav">
        <div className="ulmobil_stick_nav">
            <ul>
                <li><a href={"/ar/orchard/"+params.orchardName}><img src={homeicon} alt="home"/><p>الصفحة الرئيسية</p></a></li>
{categories!== undefined &&  categories.length > 0 && <ul className="">
{categories.map(function(item){ 
    return <li>
    <a href={"/ar/orchard/"+item.orchard_lang_slug+"/"+item.slug_name}>
    <img src={item.icon_img} alt="sub plant"/>
    {item.subplants_name}
    </a>
  </li> 
})}  
</ul>}

                <li><a href="/ar/trivia"><img src={worldicon} alt="Olives"/><p>{settings.trivia_title_ar}</p></a></li>
            </ul>
            </div>
            <div className="mobil_socials">
<p><i>{settings.contactus_title_ar}</i><a href={"tel:"+settings.contactus_num}>{settings.contactus_num}</a></p> 
                <p><i>تابعنا</i>
                <ul>
                {settings.facebook_link!=="" && <li><a href={settings.facebook_link}><i className="fa fa-facebook" aria-hidden="true"></i></a></li>}
                {settings.twitter_link!=="" && <li><a href={settings.twitter_link}><i className="fa fa-twitter" aria-hidden="true"></i></a></li>}
                {settings.linkedin_link!=="" && <li><a href={settings.linkedin_link}><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>}
                {settings.youtube_link!=="" && <li><a href={settings.youtube_link}><i className="fa fa-youtube" aria-hidden="true"></i></a></li>}
                {settings.instagram_link!=="" && <li><a href={settings.instagram_link}><i className="fa fa-instagram" aria-hidden="true"></i></a></li>}
                {settings.google_link!=="" && <li><a href={settings.google_link}><i className="fa fa-globe" aria-hidden="true"></i></a></li>}

                </ul></p>
                
            </div>
        </div>
    </header>
  );
}
export default Header;