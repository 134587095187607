import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import reportWebVitals from './reportWebVitals'; 
//import './Theme/Eng/Pages/Custom.css';
import './index.css';    
 

import Language from './language';

import Subplant from './Theme/Eng/Pages/Subplant';
import UrduSubplant from './Theme/Urdu/Pages/UrduSubplant';
import ArabicSubplant from './Theme/Arabic/Pages/ArabicSubplant';

import Trivia from './Theme/Eng/Pages/Trivia';
import UrduTrivia from './Theme/Urdu/Pages/UrduTrivia';
import ArabicTrivia from './Theme/Arabic/Pages/ArabicTrivia';

import Orchard from './Theme/Eng/Pages/Orchard';
import UrduOrchard from './Theme/Urdu/Pages/UrduOrchard';
import ArabicOrchard from './Theme/Arabic/Pages/ArabicOrchard';

import Attractions from './Theme/Eng/Pages/Attractions';
import UrduAttractions from './Theme/Urdu/Pages/UrduAttractions';
import ArabicAttractions from './Theme/Arabic/Pages/ArabicAttractions';
import App from './App';


ReactDOM.render(
     <Router>
      <Routes>
      <Route exact path="/" element={<Language />} />
      
      <Route path="/en/orchard/:orchardName" element={<Orchard />} />
      <Route path="/urd/orchard/:orchardName" element={<UrduOrchard />} /> 
      <Route path="/ar/orchard/:orchardName" element={<ArabicOrchard />} /> 

      <Route path="/en/orchard/:orchardName/:subplantname" element={<Subplant />} />
      <Route path="/urd/orchard/:orchardName/:subplantname" element={<UrduSubplant />} />
      <Route path="/ar/orchard/:orchardName/:subplantname" element={<ArabicSubplant />} />

      <Route path="/urd/trivia" element={<UrduTrivia />} />
      <Route path="/en/trivia" element={<Trivia />} />
      <Route path="/ar/trivia" element={<ArabicTrivia />} />

      <Route path="/en/attraction/:attractionName" element={<Attractions />} />
      <Route path="/ar/attraction/:attractionName" element={<ArabicAttractions />} />
      <Route path="/urd/attraction/:attractionName" element={<UrduAttractions />} />
      <Route path="/:orchardName" element={<App />} />
      </Routes>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

