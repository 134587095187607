import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';  
import '../Pages/ArabicHome.css';
import { getCategories } from "../../../utils/api";
import { useParams } from 'react-router-dom';
import $ from 'jquery';

function ArabicOrchard() { 

  const [categories, setCategories] = useState([]);
  const [orchardName, setorchardName] = useState("");
  const params = useParams()
  useEffect(() => {  
    
  getCategories(params.orchardName,'ar').then(catvalues=>{
      setCategories(catvalues.result_set);     
      setorchardName(catvalues.orchard_name); 
    });     
    
   },[]); 
var height = $( window ).height();
var min = 147;
var res = height - min;
$('.fig_olive_main').css('height',res);
$('.fig_olive_left').css('height',res);

if($(window).width() < 767)
      {
        var height = $( window ).height();
        var min = 127;
        var res = height - min;
        $('.fig_olive_main').css('height',res);
        $('.fig_olive_left').css('height',res);
        $('.fig_olive_right').css('height',res);
      }
$(".slidnav").click(function(){
  $('.fig_olive_main').toggleClass('active');  
});

 
 
    return ( 
      <div className="Apphome arabichome">
        <Header />
{categories!== undefined &&  categories.length > 0 && <section className="sectionfig">
    <div className="fig_olive_main slider">
{categories.map(function(item){  
      return <div className="fig_olive_left fadeInlocal" style={{ backgroundImage: `url(${item.subplants_banner_image})`}}>
          <div>
          <a href={"/ar/orchard/"+item.orchard_lang_slug+"/"+item.slug_name}><h2 className="fade-inlocal fadeInLeftlocal">{item.subplants_name}</h2></a>
          <a href={"/ar/orchard/"+item.orchard_lang_slug+"/"+item.slug_name}><img src={item.subplants_image} alt="figs" className="figsimg fadeInRightlocal-" /></a>
          </div>
        </div>})} 
         </div>
    <div className="fibbtn fadeInUplocal">{orchardName}</div>
</section>}
      <Footer />
      </div>


    ); 
}
export default ArabicOrchard;