import React from "react";
//import banicon from '../assets/images/banicon.png';
import longarrowleft from '../assets/images/longarrowleft.png';
import { useParams } from 'react-router-dom';

 

function Banner({headerinfo}) {

const params = useParams();

if(headerinfo.length > 0){
    return (
<div>
<div className="innerbanner fadeInlocal" style={{ backgroundImage: `url(${headerinfo[0].banner_img})`}}>
		<div className="innerbanner_content">
			<img src={headerinfo[0].icon_img} alt={headerinfo[0].subplants_name} />
			<h1>{headerinfo[0].subplants_name}</h1>
		</div>
</div>  
<div className="figstick">
	<a href={"/en/orchard/"+params.orchardName}>
	<img src={longarrowleft} className="longarrowleft" alt="figstick"/>
	<img src={headerinfo[0].icon_img} alt={headerinfo[0].subplants_name} className="baniconmob"/>
	<h2>{headerinfo[0].subplants_name}</h2>
</a>
</div>  
</div>    
    );
	}else{
		return false; 
	}
}
export default Banner;