/* eslint-disable */
import React, { useState, useEffect } from 'react';
import boxbox from '../assets/images/boxbox.png';
import Header from '../Layout/Header';

import Footer from '../Layout/Footer'; 
import Banner from './Banner';
import Sound from './Sound';
import Figstick from './Figstick'; 
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {getPageInfo}  from "../../../utils/api";

function Fig() {  

	$(window).scroll(function () {
	      if ($(window).scrollTop() > 350) {
	        $('.fixedfig').addClass('fixedfigshow');
	        $('.fixedfig').css('display','block');
            $('.mob_fig_full').addClass('banner_fixed_topincrease_yes');
	      }
	      if ($(window).scrollTop() < 350) {
	        $('.fixedfig').removeClass('fixedfigshow');
	        $('.fixedfig').css('display','block');
            $('.mob_fig_full').removeClass('banner_fixed_topincrease_yes');
	      }
    });

  $('.figcircle .figcirclebox:nth-child(1)').addClass('active');

	$(window).on('scroll', function() {
    if ($(window).scrollTop() >= $('.loopforward- .figholy:nth-child(1)').offset().top - window.innerHeight) {
        $('.figcircle .figcirclebox:nth-child(1)').addClass('active');
    }
    else 
    {
        // $('.figcircle .figcirclebox:nth-child(2)').removeClass('active');
        // $('.figcircle .figcirclebox:nth-child(3)').removeClass('active');
        $('.figcircle .figcirclebox:nth-child(1)').removeClass('active');
    }
    });
    $(window).on('scroll', function() {
      if ($(window).scrollTop() >= $('.loopforward- .figholy:nth-child(2)').offset().top - window.innerHeight) {
            $('.figcircle .figcirclebox:nth-child(1)').addClass('active');
            $('.figcircle .figcirclebox:nth-child(2)').addClass('active');
    }
    else 
    {
        //$('.figcircle .figcirclebox:nth-child(1)').removeClass('active');
        // $('.figcircle .figcirclebox:nth-child(2)').removeClass('active');
        $('.figcircle .figcirclebox:nth-child(2)').removeClass('active');
    }
    });
    $(window).on('scroll', function() {
      if ($(window).scrollTop() >= $('.loopforward- .figholy:nth-child(3)').offset().top - window.innerHeight) {
            $('.figcircle .figcirclebox:nth-child(1)').addClass('active');
            $('.figcircle .figcirclebox:nth-child(2)').addClass('active');
            $('.figcircle .figcirclebox:nth-child(3)').addClass('active');
      }
      else 
      {
         // $('.figcircle .figcirclebox:nth-child(1)').removeClass('active');
         // $('.figcircle .figcirclebox:nth-child(2)').removeClass('active');
          // $('.figcircle .figcirclebox:nth-child(3)').removeClass('active');
          $('.figcircle .figcirclebox:nth-child(3)').removeClass('active');
      }
    });

    $(window).on('scroll', function() {
      if ($(window).scrollTop() >= $('.loopforward- .figholy:nth-child(4)').offset().top - window.innerHeight) {
            $('.figcircle .figcirclebox:nth-child(1)').addClass('active');
            $('.figcircle .figcirclebox:nth-child(2)').addClass('active');
            $('.figcircle .figcirclebox:nth-child(3)').addClass('active');
            $('.figcircle .figcirclebox:nth-child(4)').addClass('active');
      }
      else 
      {
         // $('.figcircle .figcirclebox:nth-child(1)').removeClass('active');
         // $('.figcircle .figcirclebox:nth-child(2)').removeClass('active');
          // $('.figcircle .figcirclebox:nth-child(3)').removeClass('active');
        $('.figcircle .figcirclebox:nth-child(4)').removeClass('active');
      }
    });

      //   let anchorSelector = 'a[href^="#"]';
      //     $('.figcirclebox a[href^="#"]').on('click', function (e) {
      //     e.preventDefault();
      //     //let destination = $(this.hash);
      //     console.log(destination, "destination");
      //     let scrollPosition = destination.offset().top - 180;
      //     console.log(scrollPosition, 'scrollPosition');
      //     let animationDuration = 1000;
      //     $('html, body').animate({scrollTop: scrollPosition}, animationDuration);
      //  });


      // $(".figcirclebox:nth-child(1)").click(function() {
      //     console.log('new test');
      //    $('html, body').animate({scrollTop: $(".loopforward- .figholy:nth-child(1)").offset().top - 70}, 100);
      // });
      // $(".figcirclebox:nth-child(2)").click(function() {
      //     $('html, body').animate({scrollTop: $(".loopforward- .figholy:nth-child(2)").offset().top - 70}, 100);
      // });
      // $(".figcirclebox:nth-child(3)").click(function() {
      //     $('html, body').animate({scrollTop: $(".loopforward- .figholy:nth-child(3)").offset().top - 70}, 100);
      // });
      // $(".figcirclebox:nth-child(4)").click(function() {
      //     $('html, body').animate({scrollTop: $(".loopforward- .figholy:nth-child(4)").offset().top - 70}, 100);
      // });



   $(window).scroll(function () {
        if ($(window).scrollTop() > 65) {
          $('.figstick').addClass('figstick-fixed');
        }
        if ($(window).scrollTop() < 65) {
          $('.figstick').removeClass('figstick-fixed');
        }
      }); 
if ( $('#Hadith Sharif').children().length > 0 ) {
     // do something
}


	const [pageinfo, setPageinfo] = useState([]);
  const [headerinfo, setHeaderinfo] = useState([]);
  const params = useParams()
	useEffect(() => {  
		
		getPageInfo(params.subplantname,params.orchardName,'en').then(pagevalues=>{
      
      if(pagevalues.result_set !==undefined && pagevalues.result_set.length > 0){
			setPageinfo(pagevalues.result_set);     
      setHeaderinfo(pagevalues.subplants_list);   
      }
	  
		});     
		
	},[]); 
	
  // if($(window).width() > 767)
  // {
  //   let anchorSelector = 'a[href^="#"]';
  //        $('.figcirclebox a[href^="#"]').on('click', function (e) {
  //        e.preventDefault();
  //        let destination = $(this.hash);
  //        console.log(destination, "destination");
  //        let scrollPosition = destination.offset().top - 60;
  //        console.log(scrollPosition, 'scrollPosition');
  //        let animationDuration = 1000;
  //        $('html, body').animate({scrollTop: scrollPosition}, animationDuration);
  //     });
  // }

	  let totalrecords = 0;

    const handleClick = (event, param) => {
      var previous_length = 0;
      if ($(window).width() >= 768)
      {
        previous_length = $(document).find("#scroll-"+param).offset().top - 180;
      }
      else
      {
        previous_length = $(document).find("#scroll-"+param).offset().top;
      }
      
      let animationDuration = 0;
      $('html, body').animate({
      scrollTop: previous_length
      }, animationDuration);
    //   var lastScrollTop = 0;
    //   var count = 0; 
    //   var previous_length = 0;
      
    //   previous_length = $(document).find("#scroll-"+param).offset().top;

    //   console.log(previous_length, "previous_length");

    //   if($(document).find("#scroll-"+param).length > 0){

    //     count++;

    //     if (count == 1){

    //       $("html, body").animate(
    //         {
    //           scrollTop: $(document).find("#scroll-"+param).offset().top - 180
    //         },
    //         1000
    //       );

    //       return false;

    //     }
    // }

    };

    return (
      <section className="AppFig MobileAppFig Apploadcircle banner_fixed_topincrease">
            <Header />
            <Banner headerinfo={headerinfo} />
            <Sound />
        <div className="fixedfig">
	      	<div className="wrapper">
		      	<div className="figcircle reveal-load">
				  {pageinfo !== undefined && pageinfo.length > 0 && pageinfo.map(function(item){ 
		      		return <div className="figcirclebox" onClick={event => handleClick(event, item.subsection_id)}>
		      			<div>
                 <a>
    		      			<img src={item.subsection_icon} alt={item.subsection_name} />
    		      			<p>{item.subsection_name}</p>
                        </a>
		      			</div>
		      		</div>})}
		      		
		      	</div>
	      	</div>
      	</div>  
      	<div className="mob_fig_full">
      	<div className="wrapper loopforward-"> 
          {pageinfo !== undefined && pageinfo.length > 0 && pageinfo.map(function(item){ 
          return <div className="figholy topfiganileft-" id={"scroll-"+item.subsection_id}> 
          <div className="figholy_left figanileft no-anim reveal-">
            <h2><img src={item.subsection_icon} alt={item.subsection_name} /> {item.subsection_name}</h2>      				
          </div> 
          {item.infocontent.map(function(item1){ totalrecords++;
          return <div className={"figholy_right figaniright no-anim reveal-"+(totalrecords%2)}>
          <div className="thinkleft reveal">
          <h2><img src={item.subsection_icon} alt={item.subsection_name} /> {item.subsection_name}  </h2> 
          <p className="">{parse(item1.subplants_info_content)}</p>
          </div>
          <div className="hiimg reveal"><div><img src={item1.subplants_info_image} alt=" " className="holybg"/></div>
          <img src={boxbox} alt="" className="boxbox"/>
          </div>

          </div>})}
      		</div>})}
      		 
          </div>
      	</div>
      	 
            <Footer />
      </section>

      

    );
}
export default Fig;