import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer'; 
import $ from 'jquery';
import Sound from './Sound';
import TriviaBanner from './TriviaBanner';
import '../Pages/ArabicHome.css';
import Triviastick from './Triviastick'; 
import { getSettings, getAttractions } from "../../../utils/api";

import { getTravia } from "../../../utils/api";

function UrduTrivia() {

  const [trivia, setTrivia] = useState([]);
  const [settings, setSettings] = useState([]);

  useEffect(() => {  

    getSettings('urd').then(settingValues=>{
        if(settingValues.length > 0){ 
            setSettings(settingValues[0]);     
        }
  
    }); 
    
    getTravia('urd').then(triviaValues=>{
      if(triviaValues.length > 0){
        setTrivia(triviaValues); 
      }    
  
    }); 
    
   },[]); 


  $(window).scroll(function () {
        if ($(window).scrollTop() > 65) {
          $('.figstick').addClass('figstick-fixed');
        }
        if ($(window).scrollTop() < 65) {
          $('.figstick').removeClass('figstick-fixed');
        }
      });  
  
function revealUp() {
  var reveals = document.querySelectorAll(".reveal-up-");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 1;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", revealUp);

// $(".faq>.faq-box:first-child>.faq-ques").addClass("active");
// $(".faq>.faq-box:first-child").addClass("add");
// $(".faq>.faq-box:first-child>.faq-ques i").removeClass("icon-plus").addClass("icon-minus");
// $(".faq>.faq-box:first-child>.faq-ques").parent(".faq-box").find(".faq-ans").slideDown(400);
// $(".faq>.faq-box>.faq-ques").on("click", function() {
//     if ($(this).hasClass("active")) {
//       $(this).removeClass("active");
//       $(this).siblings(".faq-ans").slideUp(400);
//       $(".faq>.faq-box>.faq-ques>i").removeClass("icon-minus").addClass("icon-plus");
//       $(this).parent(".faq_title").removeClass('add');
//     } 
//     else {
//       $(".faq>.faq-box>.faq-ques>i").removeClass("icon-minus").addClass("icon-plus");
//       $(".faq-box>.faq-ans").slideUp(400);
//       $(".faq>.faq-box>.faq-ques").removeClass("active");
//       $(this).addClass("active");
//       $(this).find("i").removeClass("icon-plus").addClass("icon-minus");
//       $(this).siblings(".faq-box>.faq-ans").slideDown(400);
//       $(this).parent(".faq_title").addClass('add');
//     }
// });

$(document).ready(function() {

  $(".faq .faq-box:first-child").addClass("add");
  $(document).on('click', '.faq-box', function(e) {
      e.preventDefault();
      e.stopPropagation();
      if ($(this).hasClass("add")) {
        $(this).removeClass("add");
      }else{
        $(this).addClass("add");
      }
    });

const isMacBrowser = /(Mac|iPod|iPhone|iPad)/i.test(navigator.platform);

if(isMacBrowser)
{
  // $(".faq .faq-box:first-child .faq-ques").addClass("active");
  // $(".faq .faq-box:first-child").addClass("add");
  // $(".faq .faq-box:first-child .faq-ques i").removeClass("icon-plus").addClass("icon-minus");
  // $(".faq .faq-box:first-child .faq-ques").parent(".faq-box").find(".faq-ans").slideDown(400);
  // $(".faq .faq-box .faq-ques").on("click", function() {
  //       $(this).addClass("active");
  //       $(this).find("i").removeClass("icon-plus").addClass("icon-minus");
  //       $(this).siblings(".faq-box .faq-ans").slideDown(400);
  //       $(this).parent(".faq_title").addClass('add');
  // });
  $(".faq .faq-box:first-child").addClass("add");
  $(document).on('click', '.faq-box', function(e) {
      e.preventDefault();
      e.stopPropagation();
      if ($(this).hasClass("add")) {
        $(this).removeClass("add");
      }else{
        $(this).addClass("add");
      }
    });

}
else{
//   $(".faq .faq-box:first-child .faq-ques").addClass("active");
// $(".faq .faq-box:first-child").addClass("add");
// $(".faq .faq-box:first-child .faq-ques i").removeClass("icon-plus").addClass("icon-minus");
// $(".faq .faq-box:first-child .faq-ques").parent(".faq-box").find(".faq-ans").slideDown(400);
// $(".faq .faq-box .faq-ques").on("click", function() {
//       $(this).addClass("active");
//       $(this).find("i").removeClass("icon-plus").addClass("icon-minus");
//       $(this).siblings(".faq-box .faq-ans").slideDown(400);
//       $(this).parent(".faq_title").addClass('add');
// });
}
});

$(document).ready(function() {
if (navigator.userAgent.indexOf('Mac OS X') != -1) {
  // console.log('insidemac--1');
  // $(".faq .faq-box:first-child .faq-ques").addClass("active");
  // $(".faq .faq-box:first-child").addClass("add");
  // $(".faq .faq-box:first-child .faq-ques i").removeClass("icon-plus").addClass("icon-minus");
  // $(".faq .faq-box:first-child .faq-ques").parent(".faq-box").find(".faq-ans").slideDown(400);
  // $(".faq .faq-box .faq-ques").on("click", function() {
  //       $(this).addClass("active");
  //       $(this).find("i").removeClass("icon-plus").addClass("icon-minus");
  //       $(this).siblings(".faq-box .faq-ans").slideDown(400);
  //       $(this).parent(".faq_title").addClass('add');
  // });
  $(".faq .faq-box:first-child").addClass("add");
  $(document).on('click', '.faq-box', function(e) {
      e.preventDefault();
      e.stopPropagation();
      if ($(this).hasClass("add")) {
        $(this).removeClass("add");
      }else{
        $(this).addClass("add");
      }
    });
} else {
  
}
});


    return (
      <div className="Apphome MobileAppTrivia arabictrivia arabtrivia">
        <Header />
        <TriviaBanner title={settings.trivia_title_urd} bannerimage={settings.trivia_banner_image}/>
        <Triviastick title={settings.trivia_title_urd}/>
        <div className="wrapper faqwrapper">
          <div className="faq reveal-up"> 
          {trivia.length > 0 && trivia.map(function(item){ 
            return <div className="faq_title faq-box">
              <h2 className="faq-ques">{item.question}</h2>
              <p className="faq-ans">{item.answer}</p>
            </div>
          })}      

          </div>
        </div>
      <Sound   /> 
      <Footer />
      </div>

      

    );
}
export default UrduTrivia; 