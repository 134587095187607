import React from "react";
//import { NavLink } from "react-router-dom";
//import Header from '../Layout/Header';  
import quranicpark1 from '../assets/images/quranic-park1-.png';
  

function TriviaBanner({title,bannerimage}) {
    return (
<section className="pageinnerbanner fadeInlocal" style={{ backgroundImage: `url(${bannerimage})`}}>
		<div className="innerbanner_content">
			<h1>{title}</h1>
		</div>
</section>      

      

    );
}
export default TriviaBanner;