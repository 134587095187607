import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import "../Pages/Fontawesome.min.css";
import { getSettings, getAttractions } from "../../../utils/api";
function Footer() { 
  const [settings, setSettings] = useState([]);
  const [attractions, setAttractions] = useState([]);
  
  useEffect(() => {   
    
    getSettings('en').then(settingValues=>{
        if(settingValues.length > 0){
            if(settingValues[0] !== undefined)
            {  
               // settingsarray = settingValues[0];
               // trivia_background_image = settingsarray.trivia_contact_background_image;
               setSettings(settingValues[0]);   
            }
             
        }
   
    });     
    getAttractions('en').then(attractionValues=>{
        if(attractionValues.length > 0){
            setAttractions(attractionValues); 
        }    
  
    });
   },[]); 
 
 
    // $(".scrolltop").click(function(){
    //      $('html, body').animate({scrollTop:0}, '300');
    //       return false;
    // });
   
    function getImage(imageUrl) {
        return { backgroundImage: `url("${imageUrl}")` };
      } 
       
  return (
    <footer class="englishfooterorder">
    	
            <div className="trivia_sec" style={getImage(settings.trivia_contact_background_image)}>

            <div className="wrapper">
                <div className="trivia_main">
                    <div className="trivia_mainleft reveal">
                        <div>
                        <h2>{settings.trivia_title_en}</h2>
                        <p>{settings.trivia_desc_en}</p>
                        <a href="/en/trivia">Click Here</a>
                        </div> 
                    </div>
                    <div className="trivia_mainright reveal">
                        <div>
                        <h2>{settings.contactus_title_en}</h2>
                        <p>{settings.contactus_desc_en}</p>
                        <a href={"tel:"+settings.contactus_num}>{settings.contactus_num}</a>
                         </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="service_sec">
            <div className="wrapper">
                <p className="service_p">{settings.attract_desc_en}</p>
                <div className="service_box ani1 revealss">
                {attractions !== undefined &&  attractions.length > 0 && attractions.map(function(item){ 
                    return <div className="service_box1">
                        <div><a href={"/en/attraction/"+item.slug}><img src={item.image} alt="service" /></a></div>
                        <a className="ser__a" href={"/en/attraction/"+item.slug}>{item.title}</a>
                    </div>
                })}
                </div>
            </div>
        </div>
        <div className="wrapper">
        <div className="footer-main">
            <div className="footer-left">
                <p>{settings.copyright_en}</p>
            </div>
            <div className="footer-right">
                <p>Follow Us:</p>
                <ul>
{settings.facebook_link!=="" && <li><a href={settings.facebook_link} target="_blank"><p className="ptextre">text</p><i className="fa fa-facebook" aria-hidden="true"></i></a></li>}
{settings.twitter_link !=="" && <li><a href={settings.twitter_link} target="_blank"><p className="ptextre">text</p><i className="fa fa-twitter" aria-hidden="true"></i></a></li>}
{settings.linkedin_link !=="" && <li><a href={settings.linkedin_link} target="_blank"><p className="ptextre">text</p><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>}

{settings.youtube_link !=="" && <li><a href={settings.youtube_link} target="_blank"><p className="ptextre">text</p><i className="fa fa-youtube" aria-hidden="true"></i></a></li>}
{settings.instagram_link !=="" && <li><a href={settings.instagram_link} target="_blank"><p className="ptextre">text</p><i className="fa fa-instagram" aria-hidden="true"></i></a></li>}
{settings.google_link !=="" && <li><a href={settings.google_link} target="_blank"><p className="ptextre">text</p><i className="fa fa-globe" aria-hidden="true"></i></a></li>}      
</ul>
            </div>
        </div>
        </div>
        <div className="scrolltop" id="scrollbutton">
            <p><span><i className="fa fa-angle-up"></i></span></p>
        </div>
    </footer>
  );
}
export default Footer;