import React from "react";
import { useNavigate  } from "react-router-dom";
//import Header from '../Layout/Header';  
import banicon from '../assets/images/banicon.png';
import longarrowleft from '../assets/images/longarrowleft.png';
  

function Figstick({title}) {
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}
    return (
<section className="figstick triviastick arabic_urdu_triviastick">
	<a href="javascript:void(0);" onClick={goBack}>
	<img src={longarrowleft} className="longarrowleft" alt="figstick"/>
	<h2>{title}</h2>
</a>
</section>     
 

    );
}
export default Figstick;