import React from "react";
import './language.css';
//import { NavLink } from "react-router-dom";
import neimg from './images/bgs.png';
//import dashlogo from '../Theme/Eng/images/dashboard-logo.png'; 
import dashlogotrans from './images/dashboard-logo-trans.png';
import { redirect } from "react-router-dom";
//import {Link} from "react-router-dom"; 

function index() { 

  const  redirectToOrchardEN = ()=>{
	
	window.location = '/en/orchard/'+localStorage.getItem('slug')
  }

  const  redirectToOrcharAR = ()=>{
	window.location = '/ar/orchard/'+localStorage.getItem('slug')
} 

const  redirectToOrchardURD = ()=>{
	window.location = '/urd/orchard/'+localStorage.getItem('slug')
}

  return ( 
    <div className="dashboard-main backtransis" style={{ backgroundImage: `url(${neimg})`}}>
    	<div className="dashboard-inner">
    	<div className="dashboard-first fadeInDownlocal four">
    		<img src={dashlogotrans} alt="Dashboard Logo" className="dashlogo"/>
    		<div className="dash-btn-group">
    			<a href="#" onClick={redirectToOrchardEN} className="fadeInlocal four">English</a>
    			<a href="#" onClick={redirectToOrchardURD} className="fadeInlocal six">اردو</a>
				<a href="#" onClick={redirectToOrcharAR} className="fadeInlocal five">عربي</a>
    		</div>
    	</div>
    	</div>
    </div> 
  );
}
export default index;