const axios = require('axios').default;
//const APIBASEURL = 'http://174.129.160.230/quranicpark/api/qapi/';
let hostname = window.location.hostname;
let baseapiurl='';
//baseapiurl = window.location.protocol+'//'+hostname+':8080/api/qapi/';
baseapiurl = "http://quranic-park.ae:8080/api/qapi/";
 //console.log(baseapiurl);

const APIBASEURL = baseapiurl;
export const getCategories = async(slug, language) => {
    try {
      const response = await axios.get(APIBASEURL+'subpages_list?parent_slug='+slug+'&language_code='+language).then(response=>response.data);
      
      if(response.status==="success"){
        return response;
      }else{
        return [];
      }
    } catch (error) {
        return [];
    }
  } 

  export const getPageInfo = async(slug,orchardslug, language) => {
    try {
      const response = await axios.get(APIBASEURL+'infopages_list?subplants_name='+slug+'&orchard_slug='+orchardslug+'&language_code='+language).then(response=>response.data);
      
      if(response.status==="success"){
        return response;
      }else{
        return [];
      } 
    } catch (error) {
        return [];
    }
  } 

  export const getTravia = async(language) => {
    try {
      const response = await axios.get(APIBASEURL+'questionnarrie_list?language_code='+language).then(response=>response.data);
      
      if(response.status==="success"){ 
        return response.result_set;
      }else{
        return [];
      } 
    } catch (error) {
        return [];
    }
  } 

  export const getSettings = async(language) => {
    try {
      const response = await axios.get(APIBASEURL+'settingpage_list?language_code='+language).then(response=>response.data);
      
      if(response.status==="success"){ 
        return response.result_set;  
      }else{
        return [];
      } 
    } catch (error) {
        return [];
    }
  } 

  export const getAttractions = async(language) => {
    try {
      const response = await axios.get(APIBASEURL+'other_attractions_list?language_code='+language).then(response=>response.data);
      
      if(response.status==="success"){ 
        return response.result_set;  
      }else{ 
        return [];
      } 
    } catch (error) {
        return [];
    }
  } 

  export const getAttractionSingle = async(slug, language) => {
    try {
      const response = await axios.get(APIBASEURL+'other_attractions_details?slug_name='+slug+'&language_code='+language).then(response=>response.data);
      
      if(response.status==="success"){ 
        return response.result_set;   
      }else{ 
        return [];
      } 
    } catch (error) {
        return [];
    }
  }