import React, { useState, useEffect } from 'react';
import { getSettings } from "../../../utils/api";
//import soundfile from '../assets/images/forest-swamp-6751.mp3';
import $ from 'jquery';

function Sound() {

  const [settings, setSettings] = useState([]);

  useEffect(() => {  

    getSettings('urd').then(settingValues=>{
        if(settingValues.length > 0){ 
            setSettings(settingValues[0]);     
        }
  
    });
    
    
   },[]); 



    return (
    	<div className="audio_sec">
<audio src={settings.background_music_url} controls id="sound" loop="loop" autoplay="autoplay"></audio>


<button className="audio_btn"><i className="fa fa-volume-up" aria-hidden="true"></i>
</button>
 
</div>
 
      

    );
}
export default Sound;