import React from 'react'
import { useParams, redirect } from 'react-router-dom';


function App() {
  const params = useParams()
  localStorage.setItem('slug', params.orchardName);
  window.location = '/';
  return <div>Reloading...</div>;
  
}

export default App;
