import React, { useState, useEffect } from 'react';
import { getSettings } from "../../../utils/api";
import $ from 'jquery';
function Sound() {

  const [settings, setSettings] = useState([]);

  useEffect(() => {  

    getSettings('en').then(settingValues=>{
        if(settingValues.length > 0){ 
            setSettings(settingValues[0]);     
        }
  
    });
    
    
   },[]); 

// $('.audio_btn').on('click', function() {
//       $(this).toggleClass('volume-off'); 
//       var sound = document.getElementById("sound");
//       console.log(sound);
//       if (sound.paused) {
//         sound.play();
//     }
//     else {
//         sound.pause();
//     }
//     });


    return (
    	<div className="audio_sec">

<audio src={settings.background_music_url} controls id="sound" loop="loop" autoPlay></audio> 


<button className="audio_btn"><i className="fa fa-volume-up" aria-hidden="true"></i>
</button>
 
</div>      

    );
}
export default Sound;